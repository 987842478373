var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { class: _vm.audioClasses }, [
    _c("td", { staticClass: "table-cell table-cell__play-controls" }, [
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: _vm.beatImageOrProducerLogo(_vm.beat),
            expression: "beatImageOrProducerLogo(beat)",
          },
        ],
        staticClass: "table-cell__image",
        on: { click: _vm.togglePlayStatus },
      }),
      _c(
        "a",
        { on: { click: _vm.togglePlayStatus } },
        [
          _c("icon", { staticClass: "icon-play", attrs: { name: "play" } }),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAudioPaused && _vm.beat === _vm.beatPlaying,
                expression: "!isAudioPaused && beat === beatPlaying",
              },
            ],
            staticClass: "icon-pause",
            attrs: { name: "pause" },
          }),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAudioPaused && _vm.beat === _vm.beatPlaying,
                expression: "!isAudioPaused && beat === beatPlaying",
              },
            ],
            staticClass: "icon-speaker",
            attrs: { name: "speaker" },
          }),
        ],
        1
      ),
    ]),
    _c("td", { staticClass: "table-cell table-cell__beat-name" }, [
      _c(
        "a",
        { on: { click: _vm.togglePlayStatus } },
        [
          _vm._v(_vm._s(_vm.beat.name)),
          _vm.beat.sampled
            ? _c("icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Contains Samples",
                    expression: "'Contains Samples'",
                  },
                ],
                staticClass: "icon-copyright",
                attrs: { name: "copyright" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "table-cell--mobile-only table-cell--small-grey" },
            [_vm._v(_vm._s(_vm.beat.artist_type.name))]
          ),
        ],
        1
      ),
    ]),
    _c(
      "td",
      {
        staticClass:
          "table-cell table-cell__router-link table-cell--hide-on-mobile",
      },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: {
                name: "Beats",
                query: { artistType: _vm.beat.artist_type.slug },
              },
              title: _vm.beat.artist_type.name,
            },
          },
          [_vm._v(_vm._s(_vm.beat.artist_type.name))]
        ),
      ],
      1
    ),
    _c(
      "td",
      {
        staticClass:
          "table-cell table-cell__router-link table-cell__router-link--small table-cell--hide-on-mobile",
      },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: { name: "Beats", query: { genre: _vm.beat.genre.slug } },
              title: _vm.beat.genre.name,
            },
          },
          [_vm._v(_vm._s(_vm.beat.genre.name))]
        ),
      ],
      1
    ),
    _c("td", { staticClass: "table-cell table-cell__beat-info" }, [
      _vm._v(_vm._s(_vm.beat.bpm)),
    ]),
    _c("td", { staticClass: "table-cell table-cell__beat-info" }, [
      _vm._v(_vm._s(_vm._f("time")(_vm.beat.length))),
    ]),
    _c(
      "td",
      {
        staticClass: "table-cell table-cell--center table-cell--hide-on-mobile",
      },
      [
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Demo Download",
                expression: "'Demo Download'",
              },
            ],
            class: { isDisabled: !_vm.beat.demo_download_allowed },
            on: {
              click: function ($event) {
                return _vm.demoDownloadBeat(_vm.beat)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "icon-download",
              attrs: { name: "download" },
            }),
          ],
          1
        ),
      ]
    ),
    _c("td", { staticClass: "table-cell table-cell--center" }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: "Add To Cart",
              expression: "'Add To Cart'",
            },
          ],
          staticClass: "btn btn--sm btn--secondary",
          on: {
            click: function ($event) {
              return _vm.SET_ACTIVE_MODAL({
                modal: _vm.ModalType.LicenseOptionSelection,
                payload: _vm.beat,
              })
            },
          },
        },
        [_vm._v(_vm._s(_vm.beatAlreadyInCart ? "In Cart" : "+ ADD"))]
      ),
    ]),
    _c(
      "td",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeMenuDropdown,
            expression: "closeMenuDropdown",
          },
        ],
        staticClass: "table-cell table-cell__dropdown",
      },
      [
        _c("div", { staticClass: "table-cell--hide-on-mobile dropdown" }, [
          _c(
            "a",
            { on: { click: _vm.toggleMenuDropdown } },
            [_c("icon", { attrs: { name: "open-menu" } })],
            1
          ),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuDropDown,
                  expression: "menuDropDown",
                },
              ],
              staticClass: "dropdown__menu dropdown__menu--right",
            },
            [
              _c("li", { staticClass: "dropdown__item" }, [
                _c("a", { on: { click: _vm.openShareBeatModal } }, [
                  _vm._v("Share"),
                ]),
              ]),
              _c("hr", { staticClass: "dropdown__divider" }),
              _c("li", { staticClass: "dropdown__item" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.demoDownloadBeat(_vm.beat)
                      },
                    },
                  },
                  [_vm._v("Demo Download")]
                ),
              ]),
              _c("li", { staticClass: "dropdown__item" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.SET_ACTIVE_MODAL({
                          modal: _vm.ModalType.LicenseOptionSelection,
                          payload: _vm.beat,
                        })
                      },
                    },
                  },
                  [_vm._v("Add To Cart")]
                ),
              ]),
              _c("hr", { staticClass: "dropdown__divider" }),
              _c(
                "li",
                { staticClass: "dropdown__item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Beats",
                          query: { artistType: _vm.beat.artist_type.slug },
                        },
                      },
                    },
                    [_vm._v("Go To Artist Type")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "dropdown__item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Beats",
                          query: { genre: _vm.beat.genre.slug },
                        },
                      },
                    },
                    [_vm._v("Go To Genre")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c(
          "a",
          {
            staticClass: "table-cell--mobile-only",
            on: {
              click: function ($event) {
                return _vm.SET_ACTIVE_MODAL({
                  modal: _vm.ModalType.BeatAction,
                  payload: _vm.beat,
                })
              },
            },
          },
          [_c("icon", { attrs: { name: "open-menu" } })],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }