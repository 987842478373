var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.beatPlaylist || _vm.beatPlaylist.length <= 0
        ? _c("list-empty-message", {
            attrs: {
              "icon-name": "license",
              title: "Look like there's nothing here yet.",
              subtitle: "Producer hasn't uploaded any beats",
            },
          })
        : [
            _c("table", { staticClass: "beat-table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { width: "38" } }),
                  _c("th", { staticClass: "table-cell--padding-left" }, [
                    _vm._v("BEAT"),
                  ]),
                  _c("th", [_vm._v("ARTIST TYPE")]),
                  _c("th", [_vm._v("GENRE")]),
                  _c("th", { staticClass: "table-cell__beat-info" }, [
                    _vm._v("BPM"),
                  ]),
                  _c(
                    "th",
                    { staticClass: "table-cell__beat-info" },
                    [
                      _c("icon", {
                        staticClass: "icon-timer",
                        attrs: { name: "timer" },
                      }),
                    ],
                    1
                  ),
                  _c("th", { staticClass: "table-cell--center" }, [
                    _vm._v("DEMO DL"),
                  ]),
                  _c(
                    "th",
                    {
                      staticClass: "table-cell--center",
                      attrs: { width: "100" },
                    },
                    [_vm._v("ADD TO CART")]
                  ),
                  _c("th"),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.beatPlaylist, function (beat, index) {
                  return _c("PBeatTableItem", {
                    key: beat.id,
                    attrs: { index: index, beatPlaylist: _vm.beatPlaylist },
                  })
                }),
                1
              ),
            ]),
            _vm.showLoader
              ? _c(
                  "infinite-loading",
                  {
                    attrs: { spinner: "spiral" },
                    on: { infinite: _vm.infiniteHandler },
                  },
                  [
                    _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }),
                    _c("span", {
                      attrs: { slot: "no-results" },
                      slot: "no-results",
                    }),
                  ]
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }